import request from '@utils/pk/request'


export function fetch(query) {
    return request({
        url: 'book-group',
        method: 'get',
        params: query
    })
}
