<template>
    <div>

        <v-text-field class="mr-4 mt-n2 mb-8"
            v-model="search" append-icon="mdi-search" label="Search"
            single-line hide-details></v-text-field>

        <v-data-table
            dense
            :search="search"
            :headers="headers"
            :items="dataSource"
            class="elevation-1"
            :items-per-page="itemsPerPage"
        >
            <template v-slot:item.broker="{ item }">
                <span v-if="broker != 'MASTER'">{{ broker }}</span>
                <span v-if="broker == 'MASTER'">
                    {{ showBrokerName(item.broker_id) }}
                </span>
            </template>

            <!-- <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
            </template>
            -->
        </v-data-table>

        <v-dialog v-model="dialog" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Modify Existing Group</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" xl="4" lg="4" sm="12" md="4">
                                <v-text-field v-model="editedItem.id" label="ID" readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" xl="4" lg="4" sm="12" md="4">
                                <v-text-field v-model="editedItem.book" label="Book"></v-text-field>
                            </v-col>
                            <v-col cols="12" xl="4" lg="4" sm="12" md="4">
                                <v-text-field v-model="editedItem.group" label="Group"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState } from "vuex";
import * as apiBookGroup from "@components/api/pk/book-group";

export default {
    props: {
        headers: null,
        dataSource: null,
        itemsPerPage: null,
        broker: null
    },
    data() {
        return {
            search: "",
            dialog: false,
            dialogDelete: false,
            desserts: [],
            editedIndex: -1,
            editedItem: {
                id: -1,
                broker_id: -1,   
                group: "",
                book: "",
            },
            searchBroker: ""
        };
    },
    computed: {
        ...mapState(["brokerMap"])
    },
    methods: {
        showBrokerName(id) {
            for (let i = 0; i < this.brokerMap.length; i++) {
                if (this.brokerMap[i].broker_id == id) {
                    return this.brokerMap[i].broker;
                }
            }
            return "";
        },
        editItem(item) {

            this.editedItem.id = item.id;
            this.editedItem.broker_id = item.broker_id;
            this.editedItem.group = item.group;
            this.editedItem.book = item.book;

            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.desserts.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        close() {
            this.dialog = false;
            
        }
    },
    mounted() {

    }
};
</script>
