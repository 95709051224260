<template>
	<div class="home">
		<v-card>
			<v-container fluid pt-0 mt-0>
				<v-row class="blue-grey darken-4">
                    <Breadcrumbs/>
                </v-row>

				<v-row>
                    <v-col lg="12" md="12" xs="12" cols="12" style="margin-top: 30px;">
                        <v-btn :loading="btnLoading" class="mr-5" dense
                            color="#3a88c4" large v-on:click="refreshBookIntoRedis()">Book to Redis</v-btn>
                        <v-btn :loading="btnLoading" class="mr-5" dense
                            color="#3a88c4" large v-on:click="refreshAlertIntoRedis()">Alert to Redis</v-btn>
                    </v-col>
                </v-row>

				<v-row><v-col cols="12"></v-col></v-row>
                
                <v-row>
                    <v-col cols="12">
                        <v-tabs>
                            <v-tab v-on:click="changeView(-1)">
                                MASTER
                            </v-tab>
                            <v-tab v-on:click="changeView(item.broker_id)"
                                v-for="item in brokerMap" :key="item.broker">
                                {{ item.broker }}
                            </v-tab>
                        </v-tabs>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <h4>{{ selectedKey }} Group Book Data</h4>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <DataTable :headers="headers" :itemsPerPage="20"
                            :dataSource="groupListFull" :search="search" :broker="selectedKey" />
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>
<script>
import * as apiRedisBook from "@components/api/pk/redis-book";
import * as apiRedisAlert from "@components/api/pk/redis-alert";

import * as apiBookGroup from "@components/api/pk/book-group";
import DataTable from './components/DataTableGroup'
import moment from "moment";
moment.locale('en-au');
import Breadcrumbs from "@components/Breadcrumbs";
import {mapActions, mapState} from 'vuex';

export default {
    components:{
        DataTable, Breadcrumbs
    },
    data () {
        return {
            selectedKey: "MASTER",
            btnLoading: false,
            // groupListMissing: [],
            groupListFull: [],
            selectedBrokerId: -1,
            search:"",
            headers:[
                // { text: "Broker Id", value: "broker_id" },
                { text: "Broker", value: "broker" },
                { text: "Group", value: "group" },
                { text: "Book", value: "book" },
                { text: "Created At", value: "created_at" },
                { text: "Updated At", value: "updated_at" },
                // { text: 'Actions', value: 'actions', sortable: false },
            ]
        }
    },
    watch: {
    },
    computed: {
        ...mapState([
            'userLevel',
            'brokerId',
            'brokerMap'
        ]),
        groupListMissing() {
            return this.groupListFull.filter(item => (item.book == "" || item.book == undefined || item.book == null));
        }
    },
    methods: {
        changeView(id) {
            console.log("Current Broker Id: ", id);
            if (id == -1) {
                this.selectedKey = "MASTER";
            } else {
                let data = this.brokerMap.filter(item => item.broker_id == id);
                if (data.length != 0) this.selectedKey = data[0].broker;
            }
            this.selectedBrokerId = id;
            this.loadData();
        },
        refreshBookIntoRedis() {
            let self = this;
            self.btnLoading = true;
            apiRedisBook.create().then(res => {
                self.btnLoading = false;
            })
        },
        refreshAlertIntoRedis() {
            let self = this;
            self.btnLoading = true;
            apiRedisAlert.create().then(res => {
                self.btnLoading = false;
            })
        
        },
        loadData() {
            let self = this;
            let params = {brokerId: this.selectedBrokerId};
            self.groupListFull = [];

            apiBookGroup.fetch(params).then(res => {
                let data = res.data;
                self.groupListFull = data;
            })
        }
    },
    mounted() {
        console.log(this.brokerMap);
        this.loadData();
    }
}
</script>