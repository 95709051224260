import request from '@utils/pk/request'

export function fetch(query) {
    return request({
        url: 'redis/book',
        method: 'get',
        params: query
    })
}

export function create(query) {
    return request({
        url: 'redis/book',
        method: 'post',
        data: query
    })
}
